jQuery(function ($) {
  $(document).ready(function () {

    // media queries
    const mq = [
      window.matchMedia("(max-width: 767px)"),
      window.matchMedia("(min-width: 768px) and (max-width: 1279px)"),
      window.matchMedia("(min-width: 1280px)")
    ];


    // resize events
    let current;

    $(window).on("resize", function () {
      // positioning elements
      if (current !== "mobile" && mq[0].matches) {

        $(".nav-side-pos-js").insertAfter($(".site-headline-pos-js"));
        current = "mobile";

      } else if (current !== "tablet" && mq[1].matches) {

        $(".nav-side-pos-js").prependTo($(".site-side-pos-js"));
        current = "tablet";

      } else if (current !== "desktop" && mq[2].matches) {

        $(".nav-side-pos-js").prependTo($(".site-side-pos-js"));
        current = "desktop";

      }
    }).trigger("resize");


    // drop open and hide when click outside
    const
      links = ".dropdown-link-js",
      innerElements = ".dropdown-js";

    $(document).on("click", links, function (e) {
      if ($(this).hasClass("active")) {
        $(links).filter('a[href="' + this.hash + '"]').removeClass("active");
        $(innerElements).filter(this.hash).removeClass("open");
      } else {
        $(links).removeClass("active").filter('a[href="' + this.hash + '"]').addClass("active");
        $(innerElements).removeClass("open").filter(this.hash).addClass("open");
        $(links).add(innerElements).off();
      }
      e.preventDefault();
    }).on("mouseup touchstart keyup", function (e) {
      if ((!$(links).add(innerElements).is(e.target) && $(links).add(innerElements).has(e.target).length === 0) || e.keyCode === 27) {
        $(links).removeClass("active");
        $(innerElements).removeClass("open");
      }
    });


    // pageslide menu
    if ($.fn.pageslide) {
      $(".site-menu-toggle-js").pageslide({
        direction: "right",
        modal: true
      });
    }


    // sub menu on pageslide menu
    $("#pageslide").on("click", ".nav-main-links-js", function (e) {
      $(this).toggleClass("active").next(".nav-main-drop-js").slideToggle();
      e.preventDefault();
    });


    // sub menu on side nav
    $(document).on("click", ".nav-side-links-js", function (e) {
      $(this).toggleClass("active").next(".nav-side-sub-js").slideToggle();
      e.preventDefault();
    });


    // toggle footer column on mobile
    $(document).on("click", ".footer-column-mobile-toggle-js", function () {
      if (mq[0].matches) {
        $(this).toggleClass("active").next(".footer-column-mobile-js").slideToggle();
      }
    });


    // sliders
    if ($.fn.slick) {
      $(".exhibitions-slider-js").slick({
        arrows: true,
        fade: true,
        autoplay: true,
        adaptiveHeight: true,
        rows: 0,
        prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="icon-arrow-prev" aria-hidden="true"></i></button>',
        nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="icon-arrow-next" aria-hidden="true"></i></button>'
      });
      $(".info-columns-slider-js").slick({
        dots: false,
        autoplay: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        rows: 0,
        prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="icon-arrow-prev" aria-hidden="true"></i></button>',
        nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="icon-arrow-next" aria-hidden="true"></i></button>',
        responsive: [{
          breakpoint: 1280,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            adaptiveHeight: true
          }
        }]
      });
    }


    // custom select
    if ($.fn.selectric) {
      $("select").selectric({
        maxHeight: 200,
        nativeOnMobile: true,
        arrowButtonMarkup: '<i aria-hidden="true" class="icon-arrow-down"></i>',
        responsive: true
      });
      // events filters
      $('#handle_filters input, #handle_filters select').on('change', function(){
        $('.loader').removeClass('hide');
        $('.no-results').addClass('hide');
        var data = {cat: $('#category').val(), date_from: $('#date-from').val(), date_to: $('#date-to').val(), order: $('#sort').val()};
        $('#all_events > li').remove();
        $('ul.pages').remove();
        $.ajax({
           url: '/index.php?option=com_ajax&module=eventsfilter&method=getFilteredArticles&format=json',
           type: 'POST',
           data: data,
           success: function(data){
             setTimeout(function(){
               $('.loader').addClass('hide');
               if (data.success && data.data && typeof data.data[0] !== 'undefined') {
                 $.each(data.data, function(index, value) {
                   var html = '<li class="info_columns__items"><a href="'+value.link+'" class="info_columns__image"><img src="'+value.images+'"></a>' +
                   '<span class="site_date">'+value.date+'</span><h2><a href="'+value.link+'">'+value.title+'</a></h2></li>';
                   $('#all_events').append(html);
                 });
               } else {
                 $('.no-results').removeClass('hide');
               }
             }, 1000);
           }
        });
      });
      $('#handle_filters select').selectric({
        onChange: function() {
          $(this).trigger('change');
        }
      });
    }


    // accordion
    const accordion = function name(accordeonLinks, accordeonContainers) {
      $(document).on("click", accordeonLinks, function (e) {
        if ($(this).hasClass("active")) {
          $(accordeonLinks).filter('a[href="' + this.hash + '"]').removeClass("active");
          $(accordeonContainers).filter(this.hash).slideUp(400, function () {
            $(this).removeClass("open");
          });
        } else {
          $(accordeonLinks).filter('a[href="' + this.hash + '"]').addClass("active");
          $(accordeonContainers).filter(this.hash).slideDown(400, function () {
            $(this).addClass("open");
            if (mq[0].matches) {
              $("html, body").animate({
                scrollTop: $(this).prev().offset().top
              }, 1000);
            }
          });
        }
        e.preventDefault();
      });
    };

    accordion(".accordion-links-js", ".accordion-panels-js");


    // calendar
    if ($.fn.datepicker) {
      $(".datepicker-js").datepicker({
        prevText: '<i class="icon-arrow-prev" aria-hidden="true"></i>',
        nextText: '<i class="icon-arrow-next" aria-hidden="true"></i>',
        showOn: "both",
        buttonText: '<i aria-hidden="true" class="icon-calendar"></i>',
        showAnim: 'slideDown'
      });
      $('#date-from').datepicker('setDate', new Date());
    }


    // sticky
    if ($.fn.sticky) {
      $(window).on("resize", function () {
        const footerHeight = $(".footer-js").outerHeight() + 20;
        if (mq[0].matches) {
          $(".sticky-js").unstick();
        } else {
          $(".sticky-js").sticky({
            topSpacing: 20,
            bottomSpacing: footerHeight,
            responsiveWidth: true
          });
        }
      }).trigger("resize");
    }

    $('#searchSite .site_search__btn').on('click', function(e) {
      e.preventDefault();
      var checked = $('.select-search input:checked').val();
      var target = checked.charAt(0).toUpperCase() + checked.slice(1);
      var form = $('#search' + target);
      $(form).find('input[type="search"]').val($('form#searchSite').find('input').val());
      $(form).submit();
    });

    // table responsive
    $(".content table:not([class])").each(function () {
      while (this.attributes.length > 0) {
        this.removeAttribute(this.attributes[0].name);
      }
      $(this).wrapAll('<div class="table-responsive"></div>');
    });

    $('.site_search__btn').on('click', function(e) {
      e.preventDefault();
      switch($('#search-mode').val()) {
        case 'Site':
          var form = $('#searchSite');
          $(form).find('.search-query').val($('#site-search').val());
          $(form).submit();
          break;
        case 'Catalog':
          var form = $('#searchCatalog');
          $(form).find('.search-query').val($('#site-search').val());
          $(form).submit();
          break;
        case 'Database':
          var form = $('#searchDatabase');
          $(form).find('.search-query').val($('#site-search').val());
          $(form).submit();
          break;
        case 'Publications':
          var form = $('#searchPublications');
          $(form).find('.search-query').val($('#site-search').val());
          $(form).submit();
          break;
      }
    });

    $('#search').on('submit', function(e) {
      e.preventDefault();
      switch($('#search-mode').val()) {
        case 'Site':
          var form = $('#searchSite');
          $(form).find('.search-query').val($('#site-search').val());
          $(form).submit();
          break;
        case 'Catalog':
          var form = $('#searchCatalog');
          $(form).find('.search-query').val($('#site-search').val());
          $(form).submit();
          break;
        case 'Database':
          var form = $('#searchDatabase');
          $(form).find('.search-query').val($('#site-search').val());
          $(form).submit();
          break;
        case 'Publications':
          var form = $('#searchPublications');
          $(form).find('.search-query').val($('#site-search').val());
          $(form).submit();
          break;
      }
    });
  });
});
